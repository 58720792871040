.sf-table{
  thead {
    th {
      padding: 7px;
      font-family: $font-system;
      font-weight: 400;
      background-color: sf-color(map-get($light-theme, primary), lighter);
      color: sf-color(map-get($light-theme, primary), lighter-contrast);
    }
  }
  tbody {
    tr:nth-child(2n) {
      background-color: sf-color(map-get($light-theme, gray), lighter);
      color: sf-color(map-get($light-theme, gray), lighter-contrast);
      &:hover {
        background-color: sf-color(map-get($light-theme, gray), darker);
      }
    }
    tr, tr:nth-child(2n) {
      &:hover {
        background-color: sf-color(map-get($light-theme, gray), darker);
        color: sf-color(map-get($light-theme, gray), darker-contrast);
      }
    }
    td {
      text-align: left;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
      @extend .p1;
    }
  }

  .circle {
    width: 20px;
    height: 20px;
    border: 1px solid sf-color(map-get($light-theme, grey), darker);
    background-color: sf-color(map-get($light-theme, primary), default-contrast);
    &.green {
      background-color: sf-color(map-get($light-theme, green), darker);
    }
    &.yellow {
      background-color: sf-color(map-get($light-theme, secondary), default);
    }
    &.red {
      background-color: sf-color(map-get($light-theme, warn), default);
    }
    &.blue {
      background-color: sf-color(map-get($light-theme, primary), default);
    }
  }

  // Create icon color
  .create-icon {
    color: sf-color(map-get($light-theme, green), darker);
    &.structureOnly {
      color: sf-color(map-get($light-theme, secondary), darker);
    }
    &:hover {
      color: sf-color(map-get($light-theme, primary), default);
    }
  }

  // Fix to not break the word covered on mpg APC col
  .td-80 {
    min-width: 80px;
  }

  .active-order {
    color: sf-color(map-get($light-theme, gray), darker-contrast);
  }
}

.sf-info-box {
  svg {
    width: 30px;
  }

  p:first-child {
    margin: 0;
  }
}

blockquote {
  p:first-child {
    margin: 0;
  }
  footer {
    color: sf-color(map-get($light-theme, primary), default);
    font-weight: 400;
  }
}

.sf-blockquote {
  @extend .p2;
  color: sf-color(map-get($light-theme, primary), default);
  font-size: 1.1875rem;
  font-weight: 700;
  border-left: 4px solid sf-color(map-get($light-theme, primary), default);
}