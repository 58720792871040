@import 'sf-styles/src/colors';
@import 'sf-styles/src/theming';

body {
  @include sf-core-theme($light-theme);
}

.mdc-theme--dark {
  $background: map-get($dark-theme, background);
  background-color: $background;
  &:not(.keep-colors) {
    @include sf-core-theme($dark-theme);
    
    // dark theme overrides a11y
    a, a:visited, .mdc-button:not(.mdc-button--raised):not(:disabled) {
      color: sf-color(map-get($dark-theme, primary), default);
    }
  }
}
