@import './functions';

@import './themes/base';
@import './themes/ripple';
@import './themes/button';
@import './themes/tab-bar';
@import './themes/radio';
@import './themes/checkbox';
@import './themes/textfield';
@import './themes/select';
@import './themes/chip';

@mixin sf-core-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);

  color: $foreground;

  @include sf-link-theme($theme);
  @include mat-button-theme($theme);
  @include mat-chip-theme($theme);
  @include mat-tab-bar-theme($theme);
  @include mat-radio-theme($theme);
  @include mat-checkbox-theme($theme);
  @include mat-select-theme($theme);
  @include mat-textfield-theme($theme);
  @include mat-ripple-theme($theme);


}

