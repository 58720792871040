sf-overlay {
  .sf-overlay-window {
    position: fixed;
    z-index: 5000;
    width: 100%;
    background-color: map-get($light-theme, background);
    bottom: -100%;
    right: 0;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    transition: all 0.3s ease-in;
    &.opened {
      bottom: 0%;
    }
  }

  .sf-overlay-content {
    max-height: 60vh;
    overflow-y:auto;
  }
}