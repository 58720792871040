@mixin mat-radio-theme($theme) {

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);


  // get the default contrast color from the palette

    .mdc-radio {
      @include mdc-radio-unchecked-stroke-color(sf-color($primary, default));
      @include mdc-radio-checked-stroke-color(sf-color($primary, default));
      @include mdc-radio-ink-color(sf-color($primary, default));
      @include mdc-radio-focus-indicator-color(sf-color($primary, default));

      &:before {
        background-color: sf-color($primary, default); //ripple
      }
    }

    .mdc-radio+label {
      color: sf-color($primary, default);
    }
}
