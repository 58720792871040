body .main-footer {
  ul {
    margin-left: .5rem;
    li {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  a, a:visited {
    color: sf-color(map-get($light-theme, grey), default);
    text-decoration: none;
    &:hover {
      color: sf-color(map-get($light-theme, grey), darker);
      text-decoration: underline;
    }
    &:visited {
      color: sf-color(map-get($light-theme, grey), darker);
    }
  }
}