@use "@material/theme" with (
  $primary: #0B6B9F,
  $secondary: #F7CF07,
  $on-primary: #FFFFFF,
  $on-secondary: #012639,
);

@import 'reset';
@import 'fonts';
@import 'variables';


@import '@material/button/mdc-button';
@import '@material/elevation/mdc-elevation';
@import '@material/tab-bar/mdc-tab-bar';
@import '@material/tab-scroller/mdc-tab-scroller';
@import '@material/tab-indicator/mdc-tab-indicator';
@import '@material/tab/mdc-tab';
@import "@material/form-field/mdc-form-field";
@import "@material/textfield/mdc-text-field";
@import "@material/textfield/helper-text";
@import "@material/radio/mdc-radio";
@import "@material/checkbox/mdc-checkbox";
@import "@material/ripple/mdc-ripple";
@import "@material/select/styles";
@import "@material/list/mdc-list";
@import "@material/menu-surface/mdc-menu-surface";
@import "@material/menu/mdc-menu";

@import 'theme';

// Sciflow styles
@import 'sf/sf-layout';
@import 'sf/footer';
@import 'sf/sf-elements';
@import 'elements';

@import 'components/sf-expandable';
@import 'components/sf-modal';
@import 'components/sf-overlay';
@import 'components/sf-carousel';
@import 'components/sf-pdf-template';



body, html {
  font-family: $font-primary;
  font-size: 16px;
  background-color: map-get($light-theme, background);
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font-secondary;
}

.h00, .h0 {
  font-weight: 300;
}

h1.h-bar, h2.h-bar, h3.h-bar, h4.h-bar, h5.h-bar, h6.h-bar {
  padding-left: 10px;
  border-left: 2px solid map-get($light-theme, foreground);
}

.h-title {
  font-size: 3.6875rem;
  font-family: $font-secondary;
}

.h-subtitle {
  font-size: 1.75rem;
  font-family: $font-primary;
}

h1, .h1 {
  font-size: 2.75rem;
  font-weight: 700;
}

h2, .h2 {
  font-size: 2.0625rem;
  font-weight: 700;
}

h3, .h3 {
  font-size: 1.5625rem;
  font-weight: 700;
}

h4, .h4 {
  font-size: 1.1875rem;
  font-weight: 700;
}

h5, .h5 {
  font-weight: 700;
}

small, .small {
  font-size: 0.875rem;
}

button.button-unstyled {
  background: transparent;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

figcaption {
  font-size: 0.875rem;
}


// QUIRKS
.q-arrow-left {
  position: absolute;
  left: -8%;
  top: -5px;
}

.q-carousel-absolute {
  position: absolute;
  top: 10%;
  left: 0;
  width:100%;
  z-index: 1; // IE FIX
  // height: 500px;
}

.carousel-alt-nav {
  position: relative;
  display: block;
  .carousel-buttons {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    button {
      pointer-events: all;
      padding: 0 8px 0 8px;
      min-width: auto;
    }
  }
}

// Prevent flash of dropdown menu before js kicks in
sf-dropdown-menu:not(.hydrated), sf-mobile-menu:not(.hydrated) {
  div[slot] {
    display: none;
  }
}

@import 'vendors/basscss-sass';
@import 'utils';

@media (max-width:1023px) {
  .h00 {
    font-size: 3rem;
  }
}