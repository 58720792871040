@mixin mat-button-theme($theme) {

  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);
  $warn: map-get($theme, warn);
  $green: map-get($theme, green);
  $grey: map-get($theme, grey);

  // get the default contrast color from the palette

  .mdc-button {
      @include mdc-button-shape-radius(2px);
      font-family: $font-system;
      font-weight: 700;
      letter-spacing: inherit;

      &:disabled {
        color: sf-color($grey, darker);
        background-color: sf-color($grey, lighter);
      }

      &.mdc-button--outlined:disabled {
        background-color: transparent;
        border-color: sf-color($grey, darker);
      }

      &:not(.mdc-button--raised) {
        @include mdc-button-ink-color(sf-color($primary, darker));
      }
      &.mdc-button--raised, &.mdc-button--unelevated {
        @include mdc-button-container-fill-color(sf-color($primary, default));
        @include mdc-button-ink-color(sf-color($primary, default-contrast));
        &:hover {
          @include mdc-button-container-fill-color(sf-color($primary, lighter));
        }
        &:focus {
          @include mdc-button-container-fill-color(sf-color($primary, darker));
        }
      }

      &.mdc-button--outlined {
        @include mdc-button-outline-color(sf-color($primary, default));
      }

      &.mdc-button--secondary {
        &.mdc-button--unelevated, &.mdc-button--raised {
          @include mdc-button-container-fill-color(sf-color($secondary, default));
          @include mdc-button-ink-color(sf-color($secondary, default-contrast));
          &:hover {
            @include mdc-button-container-fill-color(sf-color($secondary, lighter));
          }
          &:focus {
            @include mdc-button-container-fill-color(sf-color($secondary, darker));
          }
        }
      }
      &.mdc-button--warn {
        @include mdc-button-container-fill-color(sf-color($warn, default));
        @include mdc-button-ink-color(sf-color($warn, default-contrast));
        &:hover {
          @include mdc-button-container-fill-color(sf-color($warn, lighter));
        }
        &:focus {
          @include mdc-button-container-fill-color(sf-color($warn, darker));
        }
      }
      &.mdc-button--cta {
        font-size: 1.1875rem;
        text-transform: none;
        height: 56px; //same height as inputs outlined
        padding: 0 50px 0 50px;
        .mdc-button__label {
          span {
            font-weight: 300;
          }
        }
      }
    }
  }
