sf-carousel {
  // background-color: lightblue;
  position: relative;


  .carousel-content {
    overflow-x: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

  }

  .carousel-buttons {
    width: 100%;
    text-align: center;
    .mdc-button__icon {
      margin: 0;
    }
  }

  .carousel-steppers {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .carousel-stepper {
      cursor: pointer;
      &.active {
        font-weight: bold;
      }
    }
  }
}