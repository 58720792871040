@mixin mat-tab-bar-theme($theme) {

  // $sf-color($primary, ligher-contrast)
  // $foreground: map-get($theme, foreground);

  $foreground: map-get($theme, foreground);

  .mdc-tab-bar {
    // @include mdc-tab-bar-width(50%);

    .mdc-tab {
      font-weight: 400;
      &.mdc-tab--active {
        font-weight: 700;
      }
      &:hover {
        .mdc-tab__text-label {
          font-weight: 700;
        }
      }
      .mdc-tab__text-label {
        color: $foreground;
        font-family: $font-system;
        opacity: 1;
      }
    }

    .mdc-tab-indicator {
      .mdc-tab-indicator__content--underline  {
        background-color: $foreground;
      }
    }

    // hide ripple because it is part of the tab-bar markup
    .mdc-tab__ripple {
      display: none;
    }

  }
}