.w-18 {
  width: 18px;
}

.w-28 {
  width: 28px;
}

.w-48 {
  width: 48px;
}

.w-60 {
  width: 60px;
}

.w-80 {
  width: 80px;
}

.w-100px {
  width: 100px;
}

.w-100 {
  width: 100%;
}

.w-128 {
  width: 128px;
}

.h-100px {
  height: 100px;
}

.h-100{
  height: 100%;
}

.h-100vh{
  min-height: 100vh;
}

.h-150 {
  height: 150px;
}

.w-150 {
  width: 150px;
}

.cursor-pointer {
  cursor: pointer;
}

.p2-5 {
  padding: 2rem;
}

.xsmall {
  font-size: 0.75rem;
}

.text-big {
  font-size: 1.1875rem;
}

body a.unstyled, body a.unstyled:visited {
  color: inherit;
  text-decoration: none;
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-contain {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-100-bottom {
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}

.hyphenate {
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.hyphenate-manually {
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: manual;
  -moz-hyphens: manual;
  hyphens: manual;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: -moz-box; // fix for FF carousel featured on small viewports
}

// To use on anchors to offset the header
.offset-anchor:before {
  display: block;
  content: " ";
  height: 60px;
  margin-top: -60px;
  visibility: hidden;
  pointer-events: none;
 }

 .offset-anchor-big:before {
  display: block;
  content: " ";
  height: 300px;
  margin-top: -300px;
  visibility: hidden;
  pointer-events: none;
 }

// This is a basscss class color override
 .border, .border-top, .border-right, .border-bottom, .border-left {
  border-color: sf-color(map-get($light-theme, grey), lighter);
}

.border-top-primary-default {
  color: sf-color(map-get($dark-theme, primary), default-contrast);
  border-top: 2px solid sf-color(map-get($dark-theme, primary), default-contrast);
}

.border-bottom-primary-default {
  border-top: 2px solid sf-color(map-get($dark-theme, primary), default-contrast);
  border-bottom-color: sf-color(map-get($light-theme, primary), default);
}

.border-primary {
  border: 2px solid sf-color(map-get($light-theme, primary), default);
}

.weight-300 {
  font-weight: 300;
}

.sr-only {
  width: 1px;
  height: 1px;
  position: absolute !important;
  clip: rect(1px,1px,1px,1px);
}

.line-trough {
  text-decoration: line-through;
}

.ar-16-9 {
  aspect-ratio: 16/9;
}

.ar-1-1 {
  aspect-ratio: 1/1;
}

.black-and-white {
  filter: grayscale(100%);
}

.poohsfavorite {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

// .z-100 {
//   z-index: 100 !important;
// }

.ws-normal {
  white-space: normal;
}

@media (max-width:1023px) {
  .visible-only-lg {
    display: none;
  }
  .hyphenate-mobile {
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
}

.flex-column-mobile {
  flex-direction: column;
}

@media (min-width: 40em) {
  .flex-column-mobile {
    flex-direction: inherit;
  } 
}

@media (min-width:1024px) {
  .lg-order-last {
    order: 99999;
  }
}

@media (max-width:831px) {
  .visible-md-up {
    display: none;
  }
}