@mixin sf-link-theme($theme) {

    $primary: map-get($theme, primary);
    $secondary: map-get($theme, secondary);
    $warn: map-get($theme, warn);
    $green: map-get($theme, green);
    $grey: map-get($theme, grey);

    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    a {
      color: $foreground;
      &:hover {
        color: sf-color($warn, lighter);
      }
    }

    a, a:visited {
      color: sf-color($primary, darker);
      text-decoration: underline;
      // TODO: talk to Victor, font weight change here may trigger layout jump
      // &:active {
      //   font-weight: 700;
      // }
      &.mdc-button {
        text-decoration: none;
      }
      &:hover {
        color: sf-color($primary, default);
      }
    }

    a.system-link {
      font-family: $font-system;
      cursor: pointer;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;
    }

    // TODO DS: when new footer merge replace anchor with this class
    a.anchor-light, a.anchor-light:visited {
      color: sf-color(map-get($light-theme, grey), default);
      text-decoration: none;
      &:hover {
        color: sf-color(map-get($light-theme, grey), darker);
        text-decoration: underline;
      }
      &:visited {
        color: sf-color(map-get($light-theme, grey), darker);
      }
    }


    /* COLOR UTILS */
    // (default, default-contrast), (lighter, lighter-contrast), (darker, darker-contrast)
    $listColorsNames: primary secondary warn green grey;
    $listTones: "default" "default-contrast" "lighter" "lighter-contrast" "darker" "darker-contrast";
    $listColors: $primary $secondary $warn $green $grey;

    @each $colorName in $listColorsNames {
      @each $tone in $listTones {
        $i: index($listColorsNames, $colorName);
        .fc-#{""+$colorName}-#{unquote($tone)} {
          color: sf-color(nth($listColors, $i), $tone);
        }
        .bc-#{""+$colorName}-#{$tone} {
          background-color: sf-color(nth($listColors, $i), unquote($tone));
        }
        .cc-#{""+$colorName}-#{$tone} {
          &:after {
            content: sf-color(nth($listColors, $i), unquote($tone));
            display: inline-block;
          }
        }
      }
    }

    .fc-light-foreground {
      color: $foreground;

    }

    .bc-light-foreground {
      background-color: $foreground;
    }

    .fc-light-background {
      color: $background;

    }

    .bc-light-background {
      background-color: $background;
    }

    .bc-primary-opacity {
      background-color: rgba(11, 107, 159, 0.1)
    }

  }


