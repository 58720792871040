.sf-permanent-promotion {
  min-height: 56px;
  background: map-get($light-theme, foreground);
  color: sf-color(map-get($light-theme, primary), default-contrast);
  font-weight: 600;
  position: relative;
  z-index: 600;

  a, a:visited, a:hover {
    text-decoration: initial;
    color: sf-color(map-get($light-theme, primary), default-contrast);
  }

  .promotion-logo {
    max-height: 2.2rem;
  }

  .promotion-text {
    margin: auto 0;
  }
}

.sf-main-header {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
  z-index: 600;

  .sf-main-header-nav {
    background-color: sf-color(map-get($light-theme, primary), default-contrast);
    height: 56px;
  }

  .logo-circle {
    width: 40px;
  }

  .logo-lettering {
    position: absolute;
    top: 8px;
    margin-left: 5px;
    width: 100px;
  }

  .logo-mobile {
    width: 145px;
  }

  // NTS: remove logo-lettering and logo-mobile when this is first deployed
  .logo-full {
    max-width: 170px;
    margin: 0 auto;
  }

  nav {
    a {
      margin-right: 30px;
      text-decoration: none;

      // font-family: $font-system;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.sf-main-container {
  max-width: $main-container-width;
  margin: 0 auto;
  z-index: 0;
}

.sf-article-container {
  max-width: $article-container-width;
  margin: 0 auto;
  z-index: 0;
}

.sf-tabs-wrapper {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 56px;
  padding-top: 30px;
  z-index: 400;
  background-color: sf-color(map-get($light-theme, primary), default-contrast);

  .sf-tabs-container {
    margin: 0 auto;
    max-width: $main-container-width;
    border-bottom: 1px solid sf-color(map-get($light-theme, grey), default);
  }

  .mdc-tab-bar {
    margin: 0 auto;
    max-width: 600px;
  }

  .sf-tabs-org {
    img {
      max-height: 75px;
    }
  }
}

.sf-logo-card {
  height: 80px;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    @extend .mdc-elevation--z8
  }

  img {
    max-width: 120px;
  }
}

.sf-quote-line {
  margin-right: 7px;
  padding-right: 7px;
  border-right: 2px sf-color(map-get($dark-theme, primary), default) solid;
}

.sf-card {
  color: map-get($light-theme, foreground);
  @extend .mdc-elevation--z2;
}

.sf-clickable-card {
  text-decoration: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  color: map-get($light-theme, foreground);
  @extend .mdc-elevation--z2;

  &:hover {
    color: map-get($light-theme, foreground);
    @extend .mdc-elevation--z4;
  }

  hr {
    border-color: sf-color(map-get($light-theme, grey), default);
  }
}

.sf-org-hero {
  .org-image {
    height: 420px;
  }

  .org-image-cc {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: map-get($light-theme, background);
  }

  .org-logo {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, 50%);

    position: absolute;
    bottom: -15%;
    width: 100%;
    text-align: center;
    pointer-events: none;

    img {
      max-width: 320px;
    }
  }
}

.sf-bottom-bar {
  z-index: 9999999;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: map-get($light-theme, background);
}

@media (max-width:1023px) {
  .sf-org-hero {
    .org-logo {
      bottom: -7%;
    }
  }
}

// To avoid adding custom classes to homepage assignments template cards
// replace if needed to be used elsewhere
sf-carousel .sf-clickable-card {
  width: 350px;
}

.sf-loader {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 10px solid rgba(sf-color(map-get($light-theme, primary), default), 0.3);
  border-top-color: sf-color(map-get($light-theme, primary), default);
  animation: 1.5s loader-spin cubic-bezier(0.5, 0, 0.3, -1) infinite alternate;

  &.double {
    border-bottom-color: sf-color(map-get($light-theme, primary), default);
  }

  &.small {
    width: 20px;
    height: 20px;
    border-width: 5px;
  }
}

.share-toolbar {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99;
}

@keyframes loader-spin {
  to {
    transform: rotate(360deg);
  }
}

// .sf-homepage {
//   font-size: 1.1875rem;
// }

.sf-section-gradient-2 {
  background: rgb(3,75,125);
  background: radial-gradient(circle, rgba(3,75,125,1) 58%, rgba(14,142,212,1) 100%);
}

.sf-section-gradient {
  
  // background: linear-gradient(180deg, sf-color(map-get($light-theme, primary), lighter) 0%, transparent 50%);
  
  // background: linear-gradient(0deg,#FAFAFA 3%,hsla(0,0%,100%,0)),linear-gradient(-257deg,#53A9D0,#5ac6f8 50%,transparent);
  background: linear-gradient(0deg,#fff 3%,rgba(255, 255, 255, 0.623)),linear-gradient(-257deg,#fff,#83d2f7 50%,transparent);
  // background: linear-gradient(0deg,#fff 3%,hsla(0,0%,100%,0)),linear-gradient(-257deg,#aaf2e1,#93edf5 50%,#dbebff)
  
}

.sf-home-hero {
  // min-height: calc(100vh - 56px);
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  a.mdc-button.mdc-button--cta {
    margin-top: 60px;
  }
}