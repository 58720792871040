// This file should be agnostic of what environment (web build or angular build) it is called from.
// Do not rely on node modules or imports to @sciflow-lib here

// https://google-webfonts-helper.herokuapp.com
// seems to use the latest font versions

/* open-sans-regular - latin_latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/open-sans-v16-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
         url('/assets/fonts/open-sans-v16-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/open-sans-v16-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/open-sans-v16-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/open-sans-v16-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/open-sans-v16-latin_latin-ext-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-700 - latin_latin-ext */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/open-sans-v16-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
         url('/assets/fonts/open-sans-v16-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/open-sans-v16-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/open-sans-v16-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/open-sans-v16-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/open-sans-v16-latin_latin-ext-700.svg#OpenSans') format('svg'); /* Legacy iOS */
  }

  /* raleway-regular - latin_latin-ext */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/raleway-v13-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: local('Raleway'), local('Raleway-Regular'),
         url('/assets/fonts/raleway-v13-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/raleway-v13-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/raleway-v13-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/raleway-v13-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/raleway-v13-latin_latin-ext-regular.svg#Raleway') format('svg'); /* Legacy iOS */
  }
  /* raleway-700 - latin_latin-ext */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/raleway-v13-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
    src: local('Raleway Bold'), local('Raleway-Bold'),
         url('/assets/fonts/raleway-v13-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/raleway-v13-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/raleway-v13-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/raleway-v13-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/raleway-v13-latin_latin-ext-700.svg#Raleway') format('svg'); /* Legacy iOS */
  }

  /* roboto-condensed-regular - latin_latin-ext */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/roboto-condensed-v17-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
         url('/assets/fonts/roboto-condensed-v17-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/roboto-condensed-v17-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/roboto-condensed-v17-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/roboto-condensed-v17-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/roboto-condensed-v17-latin_latin-ext-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  /* roboto-condensed-700 - latin_latin-ext */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/roboto-condensed-v17-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
    src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
         url('/assets/fonts/roboto-condensed-v17-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/fonts/roboto-condensed-v17-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/roboto-condensed-v17-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/roboto-condensed-v17-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/fonts/roboto-condensed-v17-latin_latin-ext-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(/assets/fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
    src: local('Material Icons'),
      local('MaterialIcons-Regular'),
      url(/assets/fonts/MaterialIcons-Regular.woff2) format('woff2'),
      url(/assets/fonts/MaterialIcons-Regular.woff) format('woff'),
      url(/assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
  }

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
  }

$font-primary: 'Open Sans', sans-serif;
$font-secondary: 'Raleway', sans-serif;
$font-system: 'Roboto Condensed', sans-serif;

$fonts: (
  primary: $font-primary,
  secondary: $font-secondary,
  system: $font-system
);
