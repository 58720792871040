// sf-modal.css inherits for both components sf-modal and sf-modal-contained
sf-modal, sf-modal-contained {

  .sf-modal-wrapper {
    display: block;
    position: absolute;
    background: rgba(map-get($light-theme, foreground), 0.5);
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 5000;
  }
  .sf-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 20px;
    background: map-get($light-theme, background);
    max-height: 90vh;
    overflow-y: auto;
    white-space: break-spaces;
  }

  &.modal-limit {
    .sf-modal-container {
      max-width: 768px;
    }
  }

  .sf-modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .citation-area {
    background-color: sf-color(map-get($light-theme, grey), lighter);
    padding: 20px;
  }

}

sf-modal-contained {
  .sf-modal-wrapper {
    position: fixed;
  }
  .sf-modal-close {
    z-index:1;
  }
}

sf-popup {
  .sf-modal-wrapper {
    // Fixes for when a scroll happens before rendering
    position: fixed;
  }
  h3.h-bar {
    text-align: center;
    padding-left: 0;
    border: none;
    // font-size: 2.0625rem;
  }
  .sf-modal-container {
    min-width: 65vw;
    border: 5px solid sf-color(map-get($light-theme, primary), default);
  }

  button.sf-modal-close.button-unstyled {
    padding: 8px;
    line-height: 1;
    background: sf-color(map-get($light-theme, primary), default);
    color: map-get($light-theme, background);
  }

  .modal-content {
    padding: 0 2rem;
    text-align: left;
  }
  .cms-content {
    font-size: 1.2rem;
    font-weight: bold;
  }
}

@media (max-width: 1023px) {
  sf-popup {
    .sf-modal-container {
      min-width: 95vw;
      border: 5px solid sf-color(map-get($light-theme, primary), default);
    }
    .modal-content {
      padding: 0;
    }
  }
}