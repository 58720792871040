sf-expandable {
  display: block;
  position: relative;
  background: sf-color(map-get($light-theme, primary), default-contrast);
  margin-bottom: 25px;
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    @extend .mdc-elevation--z4;
  }

  .expandable-inner {
    padding: 20px;
  }

  .expand-action {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    i {
      font-size: 30px;
    }
  }

  &.padding-arrow {
    [slot='expandable-content'] > *:first-child {
      padding-right: 1.8rem;
    }
  }
}