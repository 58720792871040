.article {
  p:first-child {
    margin-top: 0;
  }
}

.article-header {
  .article-cover-credit {
    top: -32px;
    padding: 8px 16px;
    background-color: rgba(sf-color(map-get($light-theme, primary), default), 0.8);
    color: sf-color(map-get($light-theme, primary), default-contrast);
    a, a:visited {
      color: sf-color(map-get($light-theme, primary), default-contrast);
      &:hover {
        text-decoration: none;
      }
    }
  }
  .article-title {
    background-color: rgba(sf-color(map-get($light-theme, primary), default), 0.8);
    color: sf-color(map-get($light-theme, primary), default-contrast);
  }
  .h00 {
    font-size: 3rem;
  }
}

.article-header.bg-cover {
  .article-title-wrapper {
    padding-top: 380px;
  }
}

@media (max-width:1023px) {
  .article-header.bg-cover.background-lg-only {
    background-image: none !important;
    .article-title-wrapper {
      padding-top: 0;
    }
  }
}

.author-box {
  border-top: 5px solid sf-color(map-get($light-theme, primary), default);
  border-bottom: 5px solid sf-color(map-get($light-theme, primary), default);
  img {
    clip-path: circle(50% at 50% 50%);
  }
}

.related-article {
  height: 250px;
  text-decoration: none;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(sf-color(map-get($light-theme, primary), default), 0);
    transition: background-color 0.3s ease-in;
  }
  &:hover {
    &:before{
      background-color: rgba(sf-color(map-get($light-theme, primary), default), 0.8);
    }
    .h4, .h5 {
      background-color: rgba(sf-color(map-get($light-theme, primary), default), 0);
    }
  }
  .h4, .h5 {
    transition: background-color 0.3s ease-in;
    background-color: rgba(sf-color(map-get($light-theme, primary), default), 0.8);
    color: sf-color(map-get($light-theme, primary), default-contrast);
  }
}

.sidebar-widget {
  .related-article {
    height: 100px;
  }
}


.sidebar-widget {
  .sidebar-widget-title {
    border-radius: 3px;
    background-color: sf-color(map-get($light-theme, primary), lighter);
    color: sf-color(map-get($light-theme, primary), default-contrast);
    font-family: $font-system;
    font-weight: 700;
  }
}

.sticky-sidebar {
  position: sticky;
  top: 60px;
}

.cta-widget {
  h1 {
    font-size: 1.5625rem;
    font-weight: 400;
    margin-top: 0;
  }
  h2 {
    font-size: 1.1875rem;
    font-weight: 400;
    margin-top: 0;
  }
  .mdc-button__label {
    span {
      font-style: italic;
    }
  }
}

.check-list {
  list-style: none;
  padding-left: 0;
  li {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: '';
      background: url(/assets/images/icon-check.svg) no-repeat 0;
      filter: brightness(100);
      background-size: 30px 24px;
      padding-left: 30px;
      margin-bottom: 26px;  
    }
    
  }
}

.badge-pricing {
  position: absolute;
  width: 150px;
  top: -137px;
  right: -76px;
  transform: rotate(20deg);
}

.post {
  .headline {
    font-weight: bold;;
    text-transform: uppercase;
    font-size: 0.625rem;
  }
  .img {
    min-height: 12.5rem;
  }
  p {
    min-height: 6.25rem;
  }
}

.sticky-post {
  img {
    border: 1px solid #00ADEE;
    border-bottom: 4px solid #00ADEE;
  }
}

a.boxed-link {
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  text-decoration: none;
  height: 100%;
  padding: 2rem;
  color: sf-color(map-get($light-theme, secondary), default-contrast);
  // background-color: sf-color(map-get($light-theme, secondary), default-contrast);
  border: 3px solid sf-color(map-get($light-theme, primary), default);
  transition: 0.3s ease-in-out;
  &:hover, &:visited:hover{
    color: sf-color(map-get($light-theme, secondary), default-contrast);
    background-color: sf-color(map-get($light-theme, primary), default);
    color: sf-color(map-get($light-theme, primary), default-contrast);
    img {
      filter: brightness(0) invert(1);
    }
  }
  img {
    margin: 0 auto;
    &.arrow {
      height: auto;
    }
  }
  &.boxed-link-replace {
    .hover-state {
      display:none;
      position: absolute;
      top: 0;
      height: 100%;
      text-align: center;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    &:hover, &:visited:hover { 
      .hover-state {
        display: flex;
      }
      img:not(.arrow) {
        opacity: 0.1;
      }
    }
  }
}

.action-card, .mdc-theme--dark:not(.keep-colors) a.action-card {
  padding: 1rem;
  height: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: sf-color(map-get($light-theme, primary), default);
  color: sf-color(map-get($light-theme, primary), default-contrast);
  &:hover, &:visited:hover {
    background-color: sf-color(map-get($light-theme, secondary), default-contrast);
    color: sf-color(map-get($light-theme, primary), default-contrast);
  }
  &:visited{
    background-color: sf-color(map-get($light-theme, primary), default);
    color: sf-color(map-get($light-theme, primary), default-contrast);
  }
}

.table-sticky-xy {
  width: 100%;
  img {
    max-width: 22px;
    vertical-align: middle;
  }

  table {
    white-space: nowrap;
    margin: 0 auto;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    // border: 1px solid sf-color(map-get($light-theme, primary), default);
    // width: 100%;
  }

  table td, table th {
    // background: #FAFAFA;
    &:nth-child(3) {
      border-left: 2px solid #C4C4C4;
      border-right: 2px solid #C4C4C4;
    }
  }

  
  table td,
  table th {
    // border: 1px solid sf-color(map-get($light-theme, primary), default);
    padding: 0.6rem 0.8rem;
    // padding: 1rem 1.5rem;
    white-space: normal;
    font-weight: normal;
  }
  table thead th {
    // padding: 3px;
    // position: sticky;
    top: 0;
    z-index: 50;
    padding-top: 2rem;
    padding-bottom: 2rem;
    top: 50px;
    &:not(:first-child) {
      border-bottom: 2px solid #C4C4C4;
    }
  }
  table td {
    // padding: 4px 5px;
    text-align: center;
  }

  tbody tr td:nth-child(2) {
    color: #777777;
    img {
      filter: grayscale(1);
    }
  }

  tbody tr td:nth-child(3) {
    color: #169bda;
    img {
      filter: brightness(1.1)
    }
  }

  tbody tr td:nth-child(4) {
    color: sf-color(map-get($light-theme, primary), default);
  }

  thead th:nth-child(3) {
    .modal-trigger-button {
     background-color: #169bda; 
    }
  }


  table :not(thead) th.head-sticky {
    position: sticky;
    top: 233px;
    z-index: 5;
  }

  table tbody th {
    font-weight: 100;
    // font-style: italic;
    text-align: left;
    position: relative;
    white-space: normal;
    word-wrap: break-word;
    min-width: 150px;
  }
  // table thead th:first-child {
  //   position: sticky;
  //   left: 0;
  //   z-index: 2;
  // }
  table tbody th {
    // position: sticky;
    // left: 0;
    // background: white;
    // z-index: 1;
  }
  caption {
    text-align: left;
    padding: 0.25rem;
    position: sticky;
    left: 0;
  }

  [role="region"][aria-labelledby][tabindex] {
    width: 100%;
    // max-height: 100vh;
    overflow: auto;
  }
  [role="region"][aria-labelledby][tabindex]:focus {
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    outline: 0;
  }
}

@media (max-width:1023px) {
  .table-sticky-xy {
    table td,
    table th  {
      padding: 0.5rem;
    }
    
  }
}

.list-availability {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    &:before {
      content: '';
      display: block;
      flex: none;
      width: 18px;
      height: 18px;
      margin-right: 0.5rem;
      background-size: 18px 18px;
      background-repeat: no-repeat;
      background-image: url('/bundles/assets/images/icon-check.svg');
    }
    &.not-available {
      text-decoration: line-through;
      color: sf-color(map-get($light-theme, grey), darker);
      &:before {
        background-image: url('/bundles/assets/images/not-available.svg');
      }
    }
  }
}

.skip-link {
  position: absolute;
  opacity: 0;
  z-index: 550;
  left: 50%;
  transform: translate(-50%, -450%);
  transition: transform 0.3s;
}

.skip-link:focus {
  transform: translate(-50%, 0%);
  opacity: 1;
}

.pro-pill {
  text-transform: uppercase;
  font-size: 0.625rem;
  padding: 5px 10px 4px 10px;
  background-color: rgba(var(--primary-color-500));
  font-weight: 700;
  color: rgba(var(--background-card));
  border-radius: 5px;
  background-color: sf-color(map-get($light-theme, primary), default);
  color: sf-color(map-get($light-theme, primary), default-contrast);
}
