//
// Default BassCss Values
// A good start https://github.com/basscss/basscss/issues/257 :/
// Although exposing the basscss root vars is what we meant to do from the begginning
//

$border-width: 1px;
$border-radius: 3px;

$width-1: 24rem;
$width-2: 32rem;
$width-3: 48rem;
$width-4: 64rem;

$space-1: .5rem;
$space-2: 1rem;
$space-3: 3rem;
$space-4: 6rem;

$z1: 1;
$z2: 2;
$z3: 3;
$z4: 4;

$h00: 3.6875rem;
$h0: 1.75rem;
$h1: 2.75rem;
$h2: 2.0625rem;
$h3: 1.5625rem;
$h4: 1.1875rem;
$h5: .875rem;
$h6: .75rem;

$line-height-1: 1;
$line-height-2: 1.125;
$line-height-3: 1.25;
$line-height-4: 1.5;
$caps-letter-spacing: .2em;
$bold-font-weight: bold;

$border-width: 3px;

$breakpoint-sm: '(min-width: 40em)';
$breakpoint-md: '(min-width: 52em)';
$breakpoint-lg: '(min-width: 64em)';

$breakpoint-sm-md: '(min-width: 40em) and (max-width: 52em)';
$breakpoint-md-lg: '(min-width: 52em) and (max-width: 64em)';


//
// BassCss and addons
//
@import 'basscss-sass/scss/basscss';
@import 'basscss-sass/scss/colors';
@import 'basscss-sass/scss/flexbox';
@import 'basscss-sass/scss/responsive-margin';
@import 'basscss-sass/scss/responsive-padding';
@import 'basscss-sass/scss/responsive-layout';
@import 'basscss-sass/scss/responsive-position';
@import 'basscss-sass/scss/responsive-typography';
@import 'basscss-sass/scss/responsive-type-scale';

//
// BassCss overrides
//

.caps {
  letter-spacing: initial;
}

