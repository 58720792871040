@mixin mat-checkbox-theme($theme) {

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);


  // get the default contrast color from the palette

    .mdc-checkbox {
      // mdc-checkbox-container-colors($unmarked-stroke-color, $unmarked-fill-color, $marked-stroke-color, $marked-fill-color, $generate-keyframes)
      @include mdc-checkbox-container-colors(
        sf-color($primary, default),
        sf-color($primary, default-contrast),
        sf-color($primary, default),
        sf-color($primary, default),
        true
      );
      @include mdc-checkbox-ink-color(sf-color($primary, default-contrast));
      // @include mdc-states(sf-color($primary, default), true);
      // &:before {
      //   background-color: sf-color($primary, default); //ripple
      // }
      // For  checkboxes inside components none JS instantiated
      .mdc-checkbox__background {
        &:before {
          background-color: sf-color($primary, default); //ripple
        }
      }

    }

}
